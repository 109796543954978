import React, { useContext } from "react"
import SEO from "../../components/seo"
import CustomAuth from "../../components/custom-auth"
import UserList from "../../components/user-list"
import { UserTenantProvider } from "../../provider/usertenantprovider"
import { UserContext } from "../../provider/usercontextprovider"

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isManager = userContext.username && userContext.signInUserSession.accessToken.payload["cognito:groups"].includes('TenantAdmin');
  return (
        <CustomAuth>
            <SEO title="Users" />
            <UserTenantProvider>
              {isManager ? <UserList/> : <p>User is not an Admin.</p>}
            </UserTenantProvider>
        </CustomAuth>
  )

}
export default ProtectedPage