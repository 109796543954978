import React from "react"
import "./user-card.css"

const UserCardContent = props => {
  return (
    <>
      <div className="card-content overflow-ellipsis">
        <div style={{display:"flex",justifyContent:"space-between"}} className="overflow-ellipsis">
          <div style={{width: "calc(100% - 25px)"}} className="overflow-ellipsis">{props.title}</div>
          {props.children}
        </div>
        <div className="card-properties">
          {props.propertyList.map(property => (
            <div key={property.name} className="overflow-ellipsis">
              <small>{property.name}: {property.value}</small>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UserCardContent
