import React from "react"

const UserCard = props => {
  return (
    <div style={{padding:"0.75em", width:"100%"}}>
      <div style={{ display: "flex", position:"relative" }}>
        <div className="initialsCircle">{props.initials}</div>
        {props.children}
      </div>
    </div>
  )
}

export default UserCard
