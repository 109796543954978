import React, { useContext, useEffect, useState } from "react"
import { LoadMaskContext }  from "../provider/loadmaskprovider"
import { API, graphqlOperation } from "aws-amplify"
import * as liststyles from "./list.module.css"
import Select from "react-select"
import { navigate } from "gatsby"
import { UserTenantContext } from "../provider/usertenantprovider"
import * as buttonStyles from './button.module.css'
import './user-list.css'
import UserCardContent from "./user-card/user-card-content"
import UserCard from "./user-card/user-card"

const listUserTenants = /* GraphQL */ `
  query listUserTenantByTenant(
    $tenantId: ID
    $limit: Int
    $nextToken: String
  ) {
    listUserTenantByTenant(tenantId: $tenantId, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        tenantId
        tenantRole
        tenantAdminGroup
        tenantProvidedUserId
        tenantObj {
          name
          description
          prettyName
          adminGroup
          fullReadAccess
          allowedRoles
          tenantAdminGroup
        }
        firstName
        lastName
      }
      nextToken
    }
  }
`

const UserListComponent = props => {
  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [state, setState] = useState({
    // tenant: localStorage.getItem('tenant') ? JSON.parse(localStorage.getItem('tenant')) : null,
    tenant: null,
    nextToken:null
  })
  const [userList, setUserList] = useState([])
  const [tenantList, setTenantList] = useContext(UserTenantContext)
  // const [nextToken, setNextToken] = useState(null)

  useEffect(() => {
    getUserList(true)
  }, [state.tenant])

  const getUserList = async (reset) => {
    if (state.tenant) {
      setIsLoading(true)
      const userListGql = await API.graphql(
        graphqlOperation(listUserTenants, {
          tenantId: state.tenant.value,
          nextToken: state.nextToken
        })
      )
      const userlist = userListGql.data.listUserTenantByTenant.items

      if(!reset)
        setUserList(userList.concat(userlist))
      else 
        setUserList(userlist)

      setState({
        ...state,
        nextToken:userListGql.data.listUserTenantByTenant.nextToken
      })
      setIsLoading(false)
    }
  }

  const onTenantChange = e => {
    // localStorage.setItem('tenant',JSON.stringify(e))
    setState({
      tenant:e,
      nextToken:null
    })
    console.log(e)
  }

  const goToUserDetails = (user) => async () => { 
    navigate('/manager/user-details',{ state: user })
  }

  const options = tenantList.filter(t=>t.tenantRole.includes("Admin")).map(tenant => {
    return {
      value: tenant.tenantObj.name,
      label: tenant.tenantObj.prettyName,
      allowedRoles: tenant.tenantObj.allowedRoles,
    }
  })

  const getIconInitials = (user) => {
    if(user.firstName && user.lastName){
      return (user.firstName.charAt(0) + user.lastName.charAt(0)).toUpperCase()
    }else{
      return user.userId.charAt(0).toUpperCase()
    }
  }

  return (
    <>
      <h1>User List</h1>
      <div>
        <Select value={state.tenant} isSearchable={false} options={options} onChange={onTenantChange} />
      </div>
      {state.tenant && userList.length > 0 && (
        <>
          <ul className={`${liststyles.listGroup} userlist`}>
            {userList.map(user => (
              <li
                style={{cursor:"pointer"}}
                onClick={goToUserDetails(user)}
                key={user.userId}
              >
                <UserCard initials={getIconInitials(user)}>
                  <UserCardContent 
                    title={user.firstName !== null ? `${user.firstName} ${user.lastName}` : user.userId} 
                    propertyList={[{name:'ID',value:user.tenantProvidedUserId},{name:'Username',value:user.userId}]}/>
                </UserCard>
               </li>
            ))}
          </ul>
          <div style={{textAlign:'center'}}>
            <button className={buttonStyles.button} style={{display: state.nextToken == null ? 'none' : 'initial'}} onClick={(e)=>{e.preventDefault(); getUserList(false);}}>Load More</button>
          </div>
        </>
      )}
    </>
  )
}

export default UserListComponent
